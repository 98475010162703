$(function() {

    $('.stock-slick').slick({
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '25%',
        arrows: false,
        responsive: [{
                breakpoint: 991,
                settings: {
                    centerPadding: '15%',
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '5%',
                }
            },
            {
                breakpoint: 575,
                settings: {
                    centerPadding: '0%',
                }
            }
        ]
    })

    $('.unique-slick').slick({
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20%',
        responsive: [{
                breakpoint: 991,
                settings: {
                    centerPadding: '10%',
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '5%',
                }
            },
            {
                breakpoint: 575,
                settings: {
                    centerPadding: '0%',
                }
            }
        ]
    })

    $('.review-slick').slick({
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '160px',
        focusOnSelect: true,
        arrows: false,
        responsive: [{
            breakpoint: 991,
            settings: {
                centerPadding: '0%',
                dots: true
            }
        }]
    })

    $('.clients-slick').slick({
        slidesToShow: 4,
        rows: 2,
        prevArrow: $('.clients-prev'),
        nextArrow: $('.clients-next'),
        dots: true,
        responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
            }
        },{
            breakpoint: 575,
            settings: {
                slidesToShow: 2,
            }
        }]
    })

    $('.history-slick').slick({
        slidesToShow: 2,
        responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
            }
        }]
    })

    $('.header-slick').slick({
        slidesToShow: 1,
        fade: true,
        dots: true
    })

    $('.quest-slick').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        asNavFor: '.quest-slick-thumbs'
    });
    $('.quest-slick-thumbs').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.quest-slick',
        centerMode: true,
        centerPadding: 0,
        focusOnSelect: true,
        responsive: [{
            breakpoint: 575,
            settings: {
                slidesToShow: 3,
            }
        }]
    });

    $("[role='phone']").mask('+7 (999) 999-99-99')

    var sticky = new Sticky('.sticky')

    $('.faq-title').on('click', function() {
        $(this).closest('.faq-items').toggleClass('open')
    })

    $('.addr > a').on('click', function() {
        console.log('Open')
        $('.addr-dd').toggleClass('open')
        $('nav').removeClass('open')
        return false
    })

    $('main, footer').on('click', function() {
        $('nav').removeClass('open')
        $('.addr-dd').removeClass('open')
    })

    $('[data-toggle="nav"].tg').on('click', function() {
        var _target = $(this).data('target');
        $('nav').removeClass('open')
        $('.addr-dd').removeClass('open')
        $('nav' + _target).toggleClass('open')
        return false;
    })

    $('menu .tg').on('click', function() {
        $(this).next('.clps').toggleClass('open')
        return false;
    })

    $('header').on('click', '.menu', function() {
        $('menu').toggle('slide', 300)
        return false
    })

    $('menu').on('click', '.close', function() {
        $('menu').toggle('slide', 300)
        return false
    })

    $('header, main, footer').on('click', function() {
        $('menu').hide('slide', 300)
    })

    $(window).on('scroll', function() {
        $('nav').removeClass('open')
    })

    $('#date').datepicker({
        'dateFormat': 'dd M',
        'numberOfMonths': 2,
        'firstDay': 1,
        'dayNamesMin': ["пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        'monthNames': [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь'
        ],
        'monthNamesShort': ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
    })

})